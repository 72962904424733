<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg assign-resources"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "manage-users",
              "edit-user",
              "assign-resources-to-user"
            )
          }}
        </div>
        <template v-slot:right>
          <!-- <button @click="toggleSearch">
            <icon icon="#cx-hea1-search" />
          </button> -->
          <router-link
            :to="{
              name: 'r_edit-user-add-resources-to-user-levels',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <section class="search-wrapper level-search" v-if="search">
      <div class="search-field">
        <form @submit.prevent="submitSearch" novalidate>
          <input
            type="text"
            ref="searchForm"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('manage-users', 'edit-user', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </form>
      </div>
    </section>
    <ul class="clebex-item-section pill icon-app1">
      <li
        v-for="resourceAssigned in resourcesAssigned"
        :key="resourceAssigned.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <template v-if="mode === 'selection'">
            <div class="checkbox tiny block alt">
              <input
                type="checkbox"
                :id="`resource${resourceAssigned.id}`"
                name="resource"
                :selected="
                  selectedAssignedResources.includes(resourceAssigned.id)
                "
                :value="resourceAssigned.id"
                @change="selectResource(resourceAssigned)"
              />
              <label :for="`resource${resourceAssigned.id}`">
                <icon icon="#cx-app1-checkmark" />
                <span class="inner-text favorite-right-info">
                  <span>
                    <span class="highlight">{{
                      resourceAssigned.resource.name
                    }}</span>
                    <span
                      v-if="
                        resourceAssigned &&
                          resourceAssigned.resource &&
                          resourceAssigned.resource.full_path
                      "
                      >,
                      {{ resourceAssigned.resource.full_path.join(", ") }}</span
                    >
                  </span>
                </span>
              </label>
            </div>
          </template>
          <template v-else>
            <router-link
              class="clebex-pill-link"
              :to="{
                name: 'r_edit-assigned-resource',
                params: {
                  resourceId: resourceAssigned.resource.id,
                  assignedResourceId: resourceAssigned.id
                }
              }"
            >
              <div class="clebex-item-content-wrapper">
                <span class="label">
                  <span class="text">
                    <span class="highlight">{{
                      resourceAssigned.resource.name
                    }}</span>
                    <span
                      v-if="
                        resourceAssigned &&
                          resourceAssigned.resource &&
                          resourceAssigned.resource.full_path
                      "
                      >,
                      {{ resourceAssigned.resource.full_path.join(", ") }}</span
                    >
                  </span>

                  <span class="text">
                    <span class="highlight">
                      {{
                        displayLabelName("manage-users", "edit-user", "mode")
                      }}:
                    </span>
                    <span
                      v-if="
                        resourceAssigned &&
                          resourceAssigned.resource_assignment_type_id === 2
                      "
                    >
                      {{
                        displayLabelName(
                          "manage-users",
                          "assign-resources",
                          "always-booked"
                        )
                      }}
                    </span>
                    <span v-else>
                      {{
                        displayLabelName(
                          "manage-users",
                          "assign-resources",
                          "required-booking"
                        )
                      }}
                    </span>
                  </span>
                </span>
                <span class="follow-up-icons">
                  <span class="follow-up-icon-item">
                    <div class="svg-icon">
                      <svg
                        width="12"
                        height="12"
                        style="width: 12px; height: 12px;"
                      >
                        <use xlink:href="#cx-app1-arrow-right-12x12"></use>
                      </svg>
                    </div>
                  </span>
                </span>
              </div>
            </router-link>
          </template>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              @click="setMode(mode === 'selection' ? null : 'selection')"
              :class="{ active: mode === 'selection' }"
            >
              {{
                displayLabelName("manage-users", "assign-resources", "select")
              }}
            </button>
          </li>
          <li class="action" :style="deleteButtonStyle">
            <button class="action-btn" @click="showDeleteModal = true">
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                (selectedAssignedResources
                  ? selectedAssignedResources.length
                  : 0) +
                "/" +
                (resourcesAssigned ? resourcesAssigned.length : 0) +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal delete-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("manage-users.edit-user.delete-title") }}
      </h3>
      <p class="text">
        {{
          displayLabelName("manage-users.assign-resources.delete-action-text")
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddAssignableResourcesToUser",
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      search: null,
      selectedAssignedResource: null,
      showDeleteModal: false,
      mode: null,
      selectedAssignedResources: []
    };
  },
  created() {
    this.$store.commit("user/setUserResourcesAssigned", null, {
      root: true
    });

    this.getUserResourcesAssigned({
      id: this.routeParam,
      params: {
        includes: ["resource_full_path"]
      }
    });
  },
  computed: {
    ...mapGetters("user", ["resourcesAssigned"]),
    routeParam() {
      const { user_id } = this.$route.params;
      return user_id;
    },
    deleteButtonStyle() {
      if (this.mode === "selection" && this.selectedAssignedResources.length) {
        return {
          visibility: "visible"
        };
      }

      return {
        visibility: "hidden"
      };
    }
  },
  methods: {
    ...mapActions("user", [
      "getUserResourcesAssigned",
      "deleteResourceAssignment"
    ]),
    toggleSearch() {
      this.search = !this.search;
    },
    setMode(mode) {
      this.mode = mode;
      this.selectedResource = null;
    },
    selectResource(resource) {
      if (this.selectedAssignedResources.includes(resource.id)) {
        this.selectedAssignedResources.splice(
          this.selectedAssignedResources.indexOf(resource.id),
          1
        );
      } else {
        this.selectedAssignedResources.push(resource.id);
      }
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedAssignedResources.length; i++) {
        await this.deleteResourceAssignment({
          userId: this.routeParam,
          id: this.selectedAssignedResources[i]
        });
      }

      this.processing = false;
      this.editing = false;
      this.selectedAssignedResources = [];
      this.selectedAssignedResource = null;
      this.getUserResourcesAssigned({
        id: this.routeParam,
        params: {
          includes: ["resource_full_path"]
        }
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
